<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
          v-model="searchParams.name"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="词条名称"
          allowClear
        ></a-input>
        <!-- 词条类型 -->
         <a-select
          style="width: 150px; margin: 5px"
          size="small"
          ref="addse"
          v-model="searchParams.itemType"
          placeholder="词条类型"
          allowClear
        >
          <a-select-option
            v-for="item of itemTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <!-- 选择大币种搜索 -->
        <a-select
          style="width: 150px; margin: 5px"
          size="small"
          @search="bigCoinSearch"
          ref="addse"
          v-model="searchParams.coinId"
          :filter-option="untils.filterOption"
          showSearch
          @change="selectBigCoin"
          placeholder="大币种"
          allowClear
        >
          <a-select-option
            v-for="item of searchBigCoins"
            :key="item.id"
            :value="item.id"
            >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
        </a-select>
         <!-- 发布状态 -->
         <a-select
          style="width: 150px; margin: 5px"
          size="small"
          ref="addse"
          v-model="searchParams.status"
          placeholder="发布状态"
          allowClear
        >
          <a-select-option
            v-for="item of sendStates"
            :key="item.value"
            :value="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <!-- 选择版别搜索 -->
        <!-- <a-select
          style="width: 150px; margin: 5px"
          size="small"
          ref="addse"
          v-model="searchParams.versionId"
          :filter-option="untils.filterOption"
          @change="selectVeision"
          placeholder="版别"
          allowClear
        >
          <a-select-option
            v-for="item of searchVersions"
            :key="item.id"
            :value="item.id"
            >{{ item.coinKindVersionName }}</a-select-option
          >
        </a-select> -->
        <a-button @click="search" size="small" style="margin-left: 10px"
          >搜索</a-button
        >
      </div>

      <div>
        <a-button @click="openModal({})" size="small">新增词条</a-button>
      </div>
    </div>
    <!-- 列表 -->
    <div class="table-content" style="padding-top: 20px">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :rowKey="(record, n) => record.id || n"
      >
        <div slot="status" slot-scope="row">
          {{ row.status }}
          <span v-if="row" style="color: green">已发布</span>
          <span v-else>未发布</span>
        </div>
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a @click="send(row)" style="margin-right: 5px">发布</a>
          <a @click="removeKnowledge(row)" style="margin-right: 5px">删除</a>
          <!-- <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.id)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm> -->
        </div>
      </a-table>
    </div>
    <!-- 新增编辑 -->
    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
      :width="700"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
       <a-form-model-item label="大币种" prop="coinKindSid">
          <a-select
            size="small"
            @search="searchData"
            ref="addse"
            v-model="forms.coinKindSid"
            :filter-option="untils.filterOption"
            showSearch
            @change="changeBigCoinSelect($event)"
            placeholder="请选择"
            allowClear
          >
            <a-select-option
              v-for="item of currency_big_list"
              :key="item.id"
              :value="item.sid"
              >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="小币种">
          <a-select
            size="small"
            mode="tags"
            :filter-option="untils.filterOption"
            showSearch
            v-model="forms.coinKindItemSid"
            @change="changeSelect($event, 2)"
            placeholder="请选择"
            allowClear
          >
            <a-select-option
              v-for="item of currency_small_list"
              :key="item.id"
              :value="item.sid"
              >{{ item.coinKindItemName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="版别名称">
          <a-select
            size="small"
            mode="tags"
            placeholder="请选择"
            :filter-option="untils.filterOption"
            showSearch
            v-model="forms.coinKindItemVersionSid"
            @change="changeSelect($event, 3)"
            allowClear
          >
            <a-select-option
              v-for="item of bottles"
              :key="item.sid"
              :value="item.sid"
              >{{ item.coinKindVersionName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="词条名称" prop="name">
          <a-input size="small" placeholder="词条名称" v-model="forms.name" />
        </a-form-model-item>
        <a-form-model-item label="词条封面" prop="coverUrl">
          <a-upload
            :multiple="true"
            :showUploadList="false"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            @change="handleChange"
          >
            <div v-if="!forms.coverUrl">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
            <img v-else :src="forms.coverUrl" style="width: 100%" />
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="词条摘要" prop="brief" v-if="forms.id && false">
          <quillEditor class="brief-editor" v-model="forms.brief"></quillEditor>
        </a-form-model-item>
        <a-form-model-item label="词条内容" prop="content">
          <div>
            <a-upload
              style="margin-right:10px;"
              :action="ip+'/file/upload'"
              :multiple="true"
              :showUploadList='false'
              @change="handleChangeEdit"
            >
              <a-button class="util-item" type="primary" size="small"
                >插入图片</a-button
              >
            </a-upload>
          </div>
          
          <quillEditor
            ref="myQuillEditor"
            :options="options"
            v-model="forms.content"
            style="min-height: 200px"
          ></quillEditor>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import quillConfig from "@/untils/qullsample.config";
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      // 发布状态
      sendStates: [
        {name: "已发布",value: 1},
        {name: "未发布",value: 0},
      ],
      // 词条类型
      itemTypes:[
        {name: "大币种词条",value: 1},
        {name: "版别词条",value: 2},
        {name: "广义词条",value: 3},
      ],
      // 搜索
      searchBigCoins: [],
      searchVersions: [],
      options: quillConfig,
      //
      bottles: [],
      currency_big_list: [],
      currency_small_list: [],
      coinsOptions: [],
      addCoinId: "", //新增币种id
      columns: [
        { title: "ID", dataIndex: "id" },
        { title: "名称", dataIndex: "name" },
        {
          title: "币种",
          dataIndex: "coinName"
        },
        {
          title: "版别",
          dataIndex: "versionName"
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {title:'更新时间',dataIndex:'updateTime'},
        {title:'操作人',
            customRender:(text, row, index) => {
                return <span>{row.adminName}</span>
            }
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      searchParams: {
        pageSize: 10,
        pageNum: 1,
        name: "",
        dynastySort: "",
        
      },
      rules: {
        name: [{ required: true, message: "please enter", trigger: "change" }],
        // brief: [{ required: true, message: "please enter", trigger: "change" }],
        // coverUrl: [
        //   { required: true, message: "please select", trigger: "change" },
        // ],
      },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      forms: {
        coinKindItemSid: "",
        coinKindItemVersionSid: "",
      },
      visible: false,
      loading: false,
      innerTitle: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    "forms.coinKindSid":{
      handler(val){
        if (val == "") {
          delete this.forms.coinId
          delete this.forms.versionId
          this.$set(this.forms, "coinKindItemSid", [])
          this.$set(this.forms, "coinKindItemVersionSid", [])
        }
      },
      deep: true
    },
    "forms.coinKindItemSid": {
      handler(val) {
        if (val && val.length == 0) {
          delete this.forms.versionId;
          this.$set(this.forms, "coinKindItemVersionSid", [])
        }
      },
      deep: true,
    },
    "forms.coinKindItemVersionSid": {
      handler(val) {
        if (val && val.length == 0) {
          delete this.forms.versionId;
        }
      },
      deep: true,
    },
  },
  components: {
    quillEditor,
  },
  created() {
    this.getData(this.searchParams);
    this.bigCoinSearch("")
    // 监听粘贴
    addEventListener("paste", (e) => {
      if (this.visible) {
        this.untils.pasteImg(e, 1, async (a, b, s) => {
          const image =  this.forms.content.replace(
            /<img *?src=[\\]{0,1}['""]{0,1}[^'""]*?data:*?['""]{0,1}.*>/g,
            `<img style="width: 100%" src="${b}" />`)
          this.$set(this.forms, "content", `<div style="white-space:pre-wrap;">`+
            `${image}`+
          `</div>`)
         
        });
      }
    });
  },
  methods: {
    // 插入图片
    handleChangeEdit(row){
        const {data} = row.file.response||{data:null}
        if (data) {
          this.$set(this.forms, "content", `<div style="white-space:pre-wrap;">`+
            `${this.forms.content ? this.forms.content : ''}`+
            `<p style="text-align:center;"><img style="width: 100%" src="${data}" /></p><p></p>`+
          `</div>`)
        }
    },
    // 搜索，大币种搜索
    async bigCoinSearch(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.searchBigCoins = records
    },
    // 搜索结果选择大币种
    selectBigCoin(id) {
      if (id == undefined) return
      delete this.searchParams.versionId
      this.versionSearch()
    },
    // 版别搜索
    async versionSearch(str){
      const res = await this.axios("/dq_admin/knowledgeItem/searchVersion", {
        params: { coinId: this.searchParams.coinId },
      })
      if (res.status != "200") return
      this.searchVersions = res.data;
    },
    // 版别选择
    selectVeision(id){
      // this.searchParams.versionId = id
    },
    // 发布词条
    async send(row) {
      const that = this;
      this.$confirm({
        title: "确认发布吗?",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          that.sendDistribute(row);
        },
        onCancel: function onCancel() {},
      });
    },
    async sendDistribute(row) {
      const res = await this.axios("/dq_admin/knowledgeItem/distribute", {
        params: {
          id: row.id,
        },
      });
      if (res.status != "200") return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getData(this.searchParams);
    },

    // 删除词条
    async removeKnowledge(row) {
      const that = this;
      this.$confirm({
        title: "确认删除吗?",
        content: "",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          that.deleteItem(row);
        },
        onCancel: function onCancel() {},
      });
    },
    async deleteItem(row) {
      const res = await this.axios("/dq_admin/knowledgeItem/delete", {
        params: {
          id: row.id,
        },
      });
      if (res.status != "200") return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getData(this.searchParams);
    },

    // 上传图片
    handleChange(res, form) {
      try {
        const { response } = res.file;
        if (response.data) {
          const { data } = response;
          if (data) {
            this.$set(this.forms, "coverUrl", data);
          }
        }
      } catch (e) {}
    },
    // 搜索大币种
    async searchData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.currency_big_list = records;
    },
    // 大币种
    changeBigCoinSelect(sid) {
      if (sid && sid.length > 1) {
        // 置空
        
        this.$set(this.forms, "coinKindItemSid", [])
        this.$set(this.forms, "coinKindItemVersionSid", [])
        const bigCoin = this.currency_big_list.find((el) => {
          return sid == el.sid;
        });
        this.forms.coinId = bigCoin.id;
        this.axios("/dq_admin/kind/getItemAndVersion", {
          params: { sid: sid },
        }).then((res) => {
          const { coinItem, coinItemVersion } = res.data;
          this.bottles = coinItemVersion;
          this.currency_small_list = coinItem;
        });
      }
    },
    changeSelect(sid, type) {
      if (sid && sid.length > 1) {
        sid.length = 1;
      } 
      if (sid && sid.length == 1) {
        //
        if (type == 1) {
          const result = this.replaceSid(
            this.currency_big_list,
            "coinKindName",
            sid.join(",")
          );
          if (result && result.length > 0) {
            sid = [result[0].sid];
          }
          this.forms.coinKindSid = sid;
          this.axios("/dq_admin/kind/getItemAndVersion", {
            params: { sid: sid.join(",") },
          }).then((res) => {
            const { coinItem, coinItemVersion } = res.data;
            this.bottles = coinItemVersion;
            this.currency_small_list = coinItem;
          });
        } else if (type == 2) {
          const result = this.replaceSid(
            this.currency_small_list,
            "coinKindItemName",
            sid.join(",")
          );
          if (result && result.length > 0) {
            sid = [result[0].sid];
          }
          this.forms.coinKindItemSid = sid;
          this.$set(this.forms, "coinKindItemVersionSid", [])
          this.axios("/dq_admin/kinditem/getInnerVersionList", {
            params: { sid: sid.join(",") },
          }).then((res) => {
            const { data } = res;
            this.bottles = data;
          });
        } else {
          // 版别
          const result = this.replaceSid(
            this.bottles,
            "coinKindVersionName",
            sid.join(",")
          );
          if (result && result.length > 0) {
            sid = [result[0].sid];
          }
          const bottle = this.bottles.find((el) => {
            return sid == el.sid;
          });
          this.forms.versionId = bottle.id;
          this.$set(this.forms, "name", bottle.coinKindVersionName)
          this.forms.coinKindItemVersionSid = sid;
        }
      }
    },
    replaceSid(array, key, name) {
      const result = array.filter((item) => item[key] == name);
      return result;
    },
    // 处理数据
    calcWikiContent() {
      this.forms.content = this.forms.content.replace(
        /class="ql-align-center"/g,
        "style='text-align:center'"
      );
      this.forms.content = this.forms.content.replace(
        /<img src/g,
        '<img style="width:100%" src'
      );
      this.forms.content = this.forms.content.replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;" )
      this.forms.content = this.forms.content.replace(/<a[^>]*?href=[\\]{0,1}['""]{0,1}[^'""]*?http*?['""]{0,1}[^>]*?>*[^>]*?<\/a>/g, "")
      
      if (this.forms.content) {
        this.forms.content = `<div style="white-space:pre-wrap;">${this.forms.content}</div>`;
      }
    },
    openModal(row) {
      this.addCoinId = "";
      const { id } = row;
      this.innerTitle = id ? "编辑词条" : "新增词条";
      this.forms = JSON.parse(JSON.stringify(row));
      if (id) {
        this.initEditPage()
      } else {
        this.currency_big_list = []
      }
      this.visible = true;
    },
    // 回显编辑页面
    async initEditPage(){
      // 大币种
      if (!this.forms.coinId) return //无大币种取消操作
      let res1 = await this.axios("/dq_admin/kind/getKindById", {
        params: { id: this.forms.coinId },
      })
      if (res1.status != "SUCCESS") return
      const bigCoin = res1.data
      this.currency_big_list = new Array()
      this.$set(this.currency_big_list, 0, bigCoin)
      this.forms.coinKindSid = bigCoin.sid
      // 版别
      if (!this.forms.versionId) return //无版别取消操作
      let res2 = await this.axios("/dq_admin/knowledgeItem/searchVersion", {
        params: { coinId: this.forms.coinId },
      })
      if (res2.status != "200") return
      this.bottles = res2.data;
      const versionObj = this.bottles.find(el=>{
        return el.id == this.forms.versionId
      })
      const new_arr = new Array()
      new_arr.push(versionObj.sid)
      this.$set(this.forms, "coinKindItemVersionSid", new_arr)
    },
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/knowledgeItem/list", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.searchParams.pageNum = 1;
      this.pagination.current = 1;
      this.getData(this.searchParams);
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.searchParams.pageNum = current;
      this.searchParams.pageSize = pageSize;
      this.getData(this.searchParams);
    },
    resetForms() {
      this.forms = {};
      this.$refs.ruleForm.resetFields();
    },
    hideModal() {
      this.calcWikiContent();
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    async submit(forms) {
      const { id } = forms;
      const url = id ? "/dq_admin/knowledgeItem/addOrEdit" : "/dq_admin/knowledgeItem/addOrEdit";
      const res = await this.axios.post(url, forms)
      if (res.status !== '200') return
      this.$message.success(res.message);
      this.visible = false;
      this.getData(this.searchParams);
    },
    async remove(params) {
      const res = await this.axios("/dq_admin/dynasty/del", {
        params: { id: params },
      });
      if (res.status == "SUCCESS") {
        this.$message.success(res.message);
        this.getData(this.searchParams);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep {
  .ant-form-item{
    margin-bottom: 10px;
  }
  .brief-editor{
    .ql-toolbar{
      display: none;
    }
    .ql-container{
      border-top: 1px solid #ccc !important;
    }
  }
} 
</style>